<script setup lang="ts">
  import { useStore } from '../../store/index'
  import { ref } from 'vue'

  const store = useStore()

  const emit = defineEmits(['nextStep'])
  const gender = ref('male')
  const preference = ref('female')

  function selectGender(genderType: string) {
    gender.value = genderType
  }

  function selectPreference(preferenceType: string) {
    preference.value = preferenceType
  }

  const currentYear = new Date().getFullYear()
  const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
  const years = range(currentYear - 19, currentYear - 120, -1)

  const birthYear = ref('ÉV')
  const birthMonth = ref('HÓNAP')
  const birthDay = ref('NAP')

  const birthdateError = ref()

  const residence = ref('Válassz megyét')
  const residenceError = ref()

  function validateDob() {
    if (birthYear.value === 'ÉV' || birthMonth.value === 'HÓNAP' || birthDay.value === 'NAP') {
      birthdateError.value = '*Add meg a születési dátumodat*'
      return false
    } else {
      birthdateError.value = undefined
      store.submitData.birthYear = birthYear.value
      store.submitData.birthDay = birthDay.value
      store.submitData.birthMonth = birthMonth.value
      return true
    }
  }

  function validateResidence() {
    if (residence.value === 'Válassz megyét') {
      residenceError.value = '*Add meg a lakhelyed*'
      return false
    } else {
      residenceError.value = undefined
      store.submitData.residence = residence.value
      return true
    }
  }

  function validateStep() {
    if (validateDob() && validateResidence()) {
      store.submitData.gender = gender.value
      store.submitData.seek = preference.value
      console.log(store.submitData)
      emit('nextStep')
    }
  }
</script>

<template>
  <div class="flex flex-col w-full px-4 mt-4">
    <div class="flex flex-row items-center w-full">
      <h2>Nemem</h2>
      <div class="flex flex-row justify-center w-full space-x-10">
        <div
          class="flex flex-col items-center justify-center border-2 rounded-full cursor-pointer w-14 h-14"
          :class="{ 'border-[#01ff8b]': gender === 'male', 'border-[#e2e2e2]': gender === 'female' }"
          @click="selectGender('male')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#0e9cb2] w-10 h-10">
            <path
              d="M208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48zM152 352V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480V256.9L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L62.85 174.6C80.2 145.7 111.4 128 145.1 128H174.9C208.6 128 239.8 145.7 257.2 174.6L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L232 256.9V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V352L152 352z"
            />
          </svg>
        </div>
        <div
          class="flex flex-col items-center justify-center border-2 rounded-full cursor-pointer w-14 h-14"
          :class="{ 'border-[#01ff8b]': gender === 'female', 'border-[#e2e2e2]': gender === 'male' }"
          @click="selectGender('female')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-10 h-10">
            <path
              d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col w-full px-4 mt-4">
    <div class="flex flex-row items-center w-full">
      <h2>Keresek</h2>
      <div class="flex flex-row justify-center w-full space-x-10">
        <div
          class="flex flex-col items-center justify-center border-2 rounded-full cursor-pointer w-14 h-14"
          :class="{ 'border-[#01ff8b]': preference === 'male', 'border-[#e2e2e2]': preference === 'female' }"
          @click="selectPreference('male')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#0e9cb2] w-10 h-10">
            <path
              d="M208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48zM152 352V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480V256.9L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L62.85 174.6C80.2 145.7 111.4 128 145.1 128H174.9C208.6 128 239.8 145.7 257.2 174.6L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L232 256.9V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V352L152 352z"
            />
          </svg>
        </div>
        <div
          class="flex flex-col items-center justify-center border-2 rounded-full cursor-pointer w-14 h-14"
          :class="{ 'border-[#01ff8b]': preference === 'female', 'border-[#e2e2e2]': preference === 'male' }"
          @click="selectPreference('female')"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-10 h-10">
            <path
              d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col px-4 mt-4 md:space-x-1 md:flex-row md:items-center">
    <h2 class="text-left min-w-max">Születési dátum</h2>

    <div class="flex flex-row w-full space-x-1">
      <select v-model="birthYear" class="block w-full font-semibold text-center bg-white rounded-md">
        <option selected disabled :value="birthYear">{{ birthYear }}</option>
        <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
      </select>

      <select v-model="birthMonth" class="block w-full font-semibold text-center bg-white rounded-md">
        <option selected disabled value="HÓNAP">{{ birthMonth }}</option>
        <option value="01">Január</option>
        <option value="02">Február</option>
        <option value="03">Március</option>
        <option value="04">Április</option>
        <option value="05">Május</option>
        <option value="06">Június</option>
        <option value="07">Július</option>
        <option value="08">Augusztus</option>
        <option value="09">Szeptember</option>
        <option value="10">Október</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
      <select v-model="birthDay" class="w-full font-semibold text-center bg-white rounded-md">
        <option selected disabled value="NAP">{{ birthDay }}</option>
        <option v-for="number in 31" :key="number" :value="number < 10 ? `0${number}` : number">
          {{ number }}
        </option>
      </select>
    </div>
  </div>
  <div v-if="birthdateError" class="flex flex-row justify-center w-full">
    <span class="font-bold text-red-400">{{ birthdateError }}</span>
  </div>
  <div class="flex flex-row items-center w-full px-4 mt-4">
    <div class="min-w-max">
      <h2 class="mr-10 text-left">Itt keresek</h2>
    </div>
    <div class="flex flex-row justify-center w-full">
      <select name="" id="" class="block w-full font-semibold text-center bg-white rounded-md" v-model="residence">
        <option value="Válassz megyét" selected disabled>Válassz megyét</option>
        <option v-for="residence in store.locations['hu']['residences']" :key="residence" :value="residence">{{ residence }}</option>
      </select>
    </div>
  </div>
  <div v-if="residenceError" class="flex flex-row justify-center w-full">
    <span class="font-bold text-red-400">{{ residenceError }}</span>
  </div>
  <div class="flex flex-row items-center justify-center mt-4">
    <!-- <button class="bg-[#00ff8a] px-20 py-3 font-semibold text-2xl rounded-full w-72" @click="emit('nextStep')">TOVÁBB</button> -->
    <button class="bg-[#00ff8a] px-20 py-3 font-semibold text-2xl rounded-full w-72" @click="validateStep()">TOVÁBB</button>
  </div>
</template>
