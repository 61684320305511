<script setup lang="ts">
  import TheHeader from '../components/TheHeader.vue'
  import TheFooter from '../components/TheFooter.vue'
  import { ref } from 'vue'
  import { useStore } from '../store/index'
  import Step1 from '../components/steps/Step1.vue'
  import Step2 from '../components/steps/Step2.vue'
  import axios from 'axios'

  const store = useStore()

  const stepNumber = ref(1)

  function increaseStep() {
    stepNumber.value++
  }

  function currentStep() {
    if (stepNumber.value === 1) return Step1
    if (stepNumber.value === 2) return Step2
  }

  const urlParameters = Object.fromEntries(new URLSearchParams(location.search.substring(1)))
  urlParameters.aff_id = `mindshu_${urlParameters.src}` || ''
  urlParameters.sub_id = urlParameters.gclid || 'direct'
  if (urlParameters.device === undefined) {
    urlParameters.device = 'mobile'
  }
  urlParameters.device = urlParameters.device.toLowerCase()
  if (urlParameters.device !== 'desktop' && urlParameters.device !== 'tablet') {
    urlParameters.device = 'mobile'
  }

  store.$patch({
    urlParameters: {
      ...urlParameters,
    },
  })

  axios.get('https://api.ipify.org?format=json').then((res) => {
    store.$patch({
      urlParameters: {
        ip: res.data.ip,
      },
    })
  })
</script>

<template>
  <div class="bg-[#1d2a3b] overflow-x-hidden relative">
    <TheHeader />
    <div class="relative w-full h-full">
      <div class="w-full h-full pb-10 bg-white blur-sm">
        <div class="w-full bg-[#ef1466] h-11"></div>
        <div class="flex flex-col w-full h-full pt-3 md:flex-row">
          <div class="flex-col items-center hidden w-1/4 md:flex">
            <ul>
              <li>Menu 1</li>
              <li>Menu 2</li>
              <li>Menu 3</li>
              <li>Menu 4</li>
              <li>Menu 5</li>
            </ul>
          </div>
          <div class="grid w-full grid-cols-2 gap-4 px-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6">
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_1.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_2.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_3.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_4.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_5.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_6.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_7.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_8.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_9.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_10.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_11.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_12.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_13.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_14.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_15.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_16.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_17.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_18.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_19.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_20.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_21.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_22.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_23.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_24.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_25.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_26.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_27.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_28.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_29.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
            <div class="flex flex-col items-center p-2 bg-gray-200 rounded-lg">
              <div class="rounded-lg w-28 h-28">
                <img src="../assets/images/grid_30.jpg" alt="" />
              </div>
              <span class="font-semibold text-center">text 1</span>
              <button class="text-[#ef1466] font-semibold bg-white px-2 rounded-lg">MEGTEKINTÉS</button>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed w-full px-3 -translate-x-1/2 top-28 md:top-16 left-1/2">
        <div class="flex items-start justify-center min-h-full text-center">
          <div class="relative text-left transition-all transform bg-white rounded-lg shadow-xl soverflow-hidden md:max-w-2xl sm:my-8 sm:mx-0">
            <div class="px-2">
              <h1 class="py-2 text-3xl font-semibold text-center">Ingyenes regisztráció</h1>
              <h2 class="mt-2 text-lg text-center">Töltsd ki az alábbi adatokat és már mutatjuk is a hölgyeket a közeledben.</h2>
            </div>
            <div class="flex flex-col w-full">
              <component :is="currentStep()" @nextStep="increaseStep()"> </component>
            </div>
            <div class="mt-5 sm:mt-6 w-full bg-[#1d2a3b] flex flex-row py-5">
              <div class="flex flex-col items-center w-full">
                <h2 class="text-[#00fe8a] text-sm font-extrabold">68%</h2>
                <div class="flex flex-row items-center justify-center bg-white rounded-full w-11 h-11">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-8 h-8">
                    <path
                      d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
                    />
                  </svg>
                </div>
                <h2 class="text-[#00fe8a] text-xs font-semibold text-center">Hölgy felhasználó</h2>
              </div>
              <div class="flex flex-col items-center w-full">
                <h2 class="text-[#00fe8a] text-sm font-extrabold">87%</h2>
                <div class="flex flex-row items-center justify-center bg-white rounded-full w-11 h-11">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-8 h-8">
                    <path
                      d="M176 288C220.1 288 256 252.1 256 208S220.1 128 176 128S96 163.9 96 208S131.9 288 176 288zM544 128H304C295.2 128 288 135.2 288 144V320H64V48C64 39.16 56.84 32 48 32h-32C7.163 32 0 39.16 0 48v416C0 472.8 7.163 480 16 480h32C56.84 480 64 472.8 64 464V416h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224C640 170.1 597 128 544 128z"
                    />
                  </svg>
                </div>
                <h2 class="text-[#00fe8a] text-xs font-semibold text-center">Alkalmi kapcsolatra nyitott</h2>
              </div>
              <div class="flex flex-col items-center w-full">
                <h2 class="text-[#00fe8a] text-sm font-extrabold">100%</h2>
                <div class="flex flex-row items-center justify-center bg-white rounded-full w-11 h-11">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-8 h-8">
                    <path
                      d="M160 0C177.7 0 192 14.33 192 32V67.68C193.6 67.89 195.1 68.12 196.7 68.35C207.3 69.93 238.9 75.02 251.9 78.31C268.1 82.65 279.4 100.1 275 117.2C270.7 134.3 253.3 144.7 236.1 140.4C226.8 137.1 198.5 133.3 187.3 131.7C155.2 126.9 127.7 129.3 108.8 136.5C90.52 143.5 82.93 153.4 80.92 164.5C78.98 175.2 80.45 181.3 82.21 185.1C84.1 189.1 87.79 193.6 95.14 198.5C111.4 209.2 136.2 216.4 168.4 225.1L171.2 225.9C199.6 233.6 234.4 243.1 260.2 260.2C274.3 269.6 287.6 282.3 295.8 299.9C304.1 317.7 305.9 337.7 302.1 358.1C295.1 397 268.1 422.4 236.4 435.6C222.8 441.2 207.8 444.8 192 446.6V480C192 497.7 177.7 512 160 512C142.3 512 128 497.7 128 480V445.1C127.6 445.1 127.1 444.1 126.7 444.9L126.5 444.9C102.2 441.1 62.07 430.6 35 418.6C18.85 411.4 11.58 392.5 18.76 376.3C25.94 360.2 44.85 352.9 60.1 360.1C81.9 369.4 116.3 378.5 136.2 381.6C168.2 386.4 194.5 383.6 212.3 376.4C229.2 369.5 236.9 359.5 239.1 347.5C241 336.8 239.6 330.7 237.8 326.9C235.9 322.9 232.2 318.4 224.9 313.5C208.6 302.8 183.8 295.6 151.6 286.9L148.8 286.1C120.4 278.4 85.58 268.9 59.76 251.8C45.65 242.4 32.43 229.7 24.22 212.1C15.89 194.3 14.08 174.3 17.95 153C25.03 114.1 53.05 89.29 85.96 76.73C98.98 71.76 113.1 68.49 128 66.73V32C128 14.33 142.3 0 160 0V0z"
                    />
                  </svg>
                </div>
                <h2 class="text-[#00fe8a] text-xs font-semibold text-center">Ingyenes regisztráció</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter />
  </div>
</template>
