export const suggestedDomainsList = {
  hu: ['@gmail.com', '@freemail.hu', '@citromail.hu', '@hotmail.com', '@indamail.hu', '@vipmail.hu'],
  br: ['@gmail.com', '@hotmail.com', '@outlook.com', '@yahoo.com', '@icloud.com'],
  cz: ['@seznam.cz', '@gmail.com', '@email.cz', '@centrum.cz', '@post.cz'],
  de: ['@gmail.com', '@web.de', '@gmx.de', '@yahoo.com', '@hotmail.com'],
  uk: ['@gmail.com', '@hotmail.com', '@yahoo.com', '@outlook.com', '@hotmail.co.uk'],
  us: ['@gmail.com', '@hotmail.com', '@icloud.com', '@yahoo.com', '@outlook.com'],
  es: ['@gmail.com', '@hotmail.com', '@hotmail.es', '@yahoo.com', '@outlook.com'],
  fr: ['@gmail.com', '@hotmail.fr', '@hotmail.com', '@outlook.fr'],
  in: ['@gmail.com', '@yahoo.com'],
  it: ['@gmail.com', '@libero.it', '@hotmail.com', '@yahoo.com'],
  nl: ['@gmail.com', '@hotmail.com', '@outlook.com', '@live.nl'],
  pl: ['@gmail.com', '@wp.pl', '@o2.pl', '@interia.pl', '@onet.pl'],
  ro: ['@gmail.com', '@yahoo.com', '@hotmail.com', '@icloud.com'],
  si: ['@gmail.com', '@hotmail.com', '@yahoo.com'],
  sk: ['@gmail.com', '@azet.sk', '@centrum.sk'],
  tr: ['@gmail.com', '@hotmail.com', '@icloud.com', '@outlook.com'],
}

export type Domains = keyof typeof suggestedDomainsList
