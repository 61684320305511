import { defineStore } from 'pinia'

type State = {
  urlParameters:
    | void
    | ({
        lang?: string
      } & Record<string, string>)
  submitData: any
  locations: any
}

export const useStore = defineStore('main', {
  state: (): State => {
    return {
      urlParameters: undefined,
      submitData: {
        wtUrl: 'https://wt.pstbck.net/items/admolly',
        wtAdmollyEndpoint: 'https://wt.pstbck.net/admolly',
        gender: undefined,
        birthYear: undefined,
        birthMonth: undefined,
        birthDay: undefined,
        residence: undefined,
        seek: undefined,
        username: undefined,
        password: undefined,
        email: undefined,
      },
      locations: {
        uk: {
          reqId: 'mindscrapersuk',
          name: 'United Kingdom-',
          residences: [
            'East Midlands',
            'East of England',
            'Greater London',
            'North East England',
            'North West England',
            'South East England',
            'West Midlands',
            'Yorkshire and the Humber',
            'Northern ireland',
            'Scotland',
            'Wales',
          ],
        },
        hu: {
          reqId: 'mindshu',
          name: 'Magyarország-',
          residences: [
            'Bács Kiskun',
            'Baranya',
            'Békés',
            'Borsod Abaúj Zemplén',
            'Budapest',
            'Csongrád Csanád',
            'Fejér',
            'Győr Moson Sopron',
            'Hajdú Bihar',
            'Heves',
            'Jász Nagykun Szolnok',
            'Komárom Esztergom',
            'Nógrád',
            'Pest',
            'Somogy',
            'Szabolcs Szatmár Bereg',
            'Tolna',
            'Vas',
            'Veszprém',
            'Zala',
          ],
        },
        au: {
          reqId: 'mindscrapersaus',
          name: 'Australia-',
          residences: ['Australian Capital Territory', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia'],
        },
        ca: {
          reqId: 'mindscrapersca',
          name: 'Canada-',
          residences: [
            'Alberta',
            'British Columbia',
            'Manitoba',
            'New Brunswick',
            'Newfoundland and Labrador',
            'Northwest Territories',
            'Nova Scotia',
            'Nunavut',
            'Ontario',
            'Prince Edward Island',
            'Quebec',
            'Saskatchewan',
            'Yukon',
          ],
        },
        de: {
          reqId: 'mindscrapersdach',
          name: 'Germany-',
          residences: [
            'Baden Wurttemberg',
            'Bayern',
            'Berlin',
            'Brandenburg',
            'Bremen',
            'Hamburg',
            'Hessen',
            'Mecklenburg Vorpommern',
            'Niedersachsen',
            'Nordrhein Westfalen',
            'Rheinland Pfalz',
            'Saarland',
            'Sachsen',
            'Sachsen Anhalt',
            'Schleswig Holstein',
            'Thüringen',
          ],
        },
        it: {
          reqId: 'mindscrapersit',
          name: 'Italy-',
          residences: [
            'Abruzzo',
            'Basilicata',
            'Calabria',
            'Campania',
            'Emilia-Romagna',
            'Friuli-Venezia Giulia',
            'Lazio',
            'Liguria',
            'Lombardia',
            'Marche',
            'Molise',
            'Piemonte',
            'Puglia',
            'Sardegna',
            'Sicilia',
            'Toscana',
            'Trentino-Alto Adige',
            'Umbria',
            'Valle d"Aosta',
            'Veneto',
          ],
        },
        us: {
          reqId: 'mindscrapersus',
          name: 'United States-',
          residences: [
            'Alabama',
            'Alaska',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'Florida',
            'Georgia',
            'Hawaii',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
          ],
        },
      },
    }
  },
  persist: false,
})
