import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// import i18n from './i18n'
import { MotionPlugin } from '@vueuse/motion'
import router from './router/index'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(createPinia().use(piniaPluginPersistedstate))
// app.use(i18n)
app.use(MotionPlugin)
app.use(router)
app.use(VueGtag, {
  config: { id: 'AW-1018861338' },
})

app.mount('#app')
