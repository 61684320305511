<script setup lang="ts">
  import { ref, watchEffect } from 'vue'
  import { useStore } from '../../store/index'
  import axios from 'axios'
  import { suggestedDomainsList } from '../../utils/constants'
  import { event } from 'vue-gtag'

  const emit = defineEmits(['nextStep'])
  const store = useStore()

  const username = ref('')
  const usernameError = ref()
  const usernameAllowedChars = /^[A-Za-z-]+$/

  function validateUsername() {
    if (username.value.length < 3 || username.value.length > 15) {
      usernameError.value = '*Minimum 3 és maximum 15 karakter lehet*'
      return false
    } else if (!usernameAllowedChars.test(username.value)) {
      usernameError.value = '*Csak az ABC betüi és a kötőjel engedélyezett*'
      return false
    } else {
      usernameError.value = undefined
      store.submitData.username = username.value
      return true
    }
  }

  const password = ref('')
  const passwordError = ref()

  function validatePassword() {
    if (password.value.length < 6 || password.value.length > 100) {
      passwordError.value = '*Minimum 6 és maximum 100 karakter lehet*'
      return false
    } else {
      passwordError.value = undefined
      store.submitData.password = password.value
      return true
    }
  }

  const email = ref('')
  const emailError = ref()
  const isLoading = ref(false)
  const lpDomain = ref(window.location.host + window.location.pathname)

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  function submitGoogleConversion() {
    event('conversion', { send_to: 'AW-1018861338/eaMSCIS0g8oDEJqu6uUD' })
  }

  function validateEmail() {
    isLoading.value = true
    if (!re.test(email.value)) {
      emailError.value = '*Adj meg egy valós e-mail címet*'
      isLoading.value = false
      return false
    } else {
      emailError.value = undefined
      isLoading.value = false
      store.submitData.email = email.value
      return true
    }
  }

  function submitFormData() {
    isLoading.value = true
    axios({
      method: 'post',
      url: store.submitData.wtAdmollyEndpoint,
      data: {
        username: store.submitData.username,
        email: store.submitData.email,
        gender: store.submitData.gender,
        seek: store.submitData.seek,
        password: store.submitData.password,
        ip: store.urlParameters?.ip,
        location: store.locations['hu']['name'] + store.submitData.residence.replace('-', ' '),
        birth_year: store.submitData.birthYear,
        birth_month: store.submitData.birthMonth,
        birth_day: store.submitData.birthDay,
        device: store.urlParameters?.device,
        req_id: store.locations['hu']['reqId'],
        aff_id: store.urlParameters?.aff_id,
        sub_id: store.urlParameters?.sub_id,
      },
    })
      .then((response) => {
        if (response.data.status === 2 || response.data.status === 6) {
          isLoading.value = false
          emailError.value = '*Próbáld meg egy másik e-mail címmel*'
        } else {
          axios
            .post(store.submitData.wtUrl, {
              username: store.submitData.username,
              email: store.submitData.email,
              gender: store.submitData.gender,
              seek: store.submitData.seek,
              password: store.submitData.password,
              location: store.locations['hu']['name'] + store.submitData.residence.replace('-', ' '),
              birth_year: store.submitData.birthYear,
              birth_month: store.submitData.birthMonth,
              birth_day: store.submitData.birthDay,
              req_id: store.locations['hu']['reqId'],
              aff_id: store.urlParameters?.aff_id,
              lp_domain: lpDomain.value,
              ...store.urlParameters,
            })
            .then(() => {
              submitGoogleConversion()
              window.location.href = response.data
            })
            .catch(() => {
              submitGoogleConversion()
              window.location.href = response.data
            })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function validateStep() {
    if (validateUsername() && validatePassword() && validateEmail()) {
      submitFormData()
    }
  }

  const showSuggestion = ref(false)

  const suggestedResults = () =>
    suggestedDomainsList['hu'].map((domain: string) => {
      return email.value.split('@')[0] + domain
    })

  const watcher = watchEffect(() => {
    if (email.value.includes('@')) {
      suggestedResults()
      showSuggestion.value = true
    }
    if (re.test(email.value)) {
      showSuggestion.value = false
    }
  })

  function selectDomain(address: string) {
    email.value = address
    showSuggestion.value = false
  }
</script>

<template>
  <div class="flex flex-row items-center px-4 mt-4">
    <div class="w-44">
      <h2 class="text-left">Felhasználónév</h2>
    </div>
    <div class="w-full">
      <input type="text" autocomplete="off" class="w-full rounded-md" v-model="username" />
    </div>
  </div>
  <div v-if="usernameError" class="flex flex-row justify-center w-full">
    <span class="font-bold text-red-400">{{ usernameError }}</span>
  </div>
  <div class="flex flex-row items-center justify-start px-4 mt-4">
    <div class="w-44">
      <h2 class="text-left">Jelszó</h2>
    </div>
    <div class="w-full">
      <input type="password" autocomplete="off" class="w-full rounded-md" v-model="password" />
    </div>
  </div>
  <div v-if="passwordError" class="flex flex-row justify-center w-full">
    <span class="font-bold text-red-400">{{ passwordError }}</span>
  </div>
  <div class="flex flex-row items-center justify-start px-4 mt-4">
    <div class="w-44">
      <h2 class="text-left">E-mail</h2>
    </div>
    <div class="w-full">
      <input type="email" autocomplete="off" class="w-full rounded-md" v-model="email" />
      <div class="relative w-full">
        <ul v-if="showSuggestion && store.urlParameters?.ehelp" class="absolute w-full overflow-y-scroll bg-white border border-gray-300 rounded h-28">
          <li v-for="domain in suggestedResults()" :key="domain" class="p-1 cursor-pointer hover:bg-gray-400" @click="selectDomain(domain)">
            {{ domain }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-if="emailError" class="flex flex-row justify-center w-full">
    <span class="font-bold text-red-400">{{ emailError }}</span>
  </div>
  <div class="flex flex-col items-center justify-end w-full h-full mt-4">
    <button v-if="!isLoading" class="bg-[#00ff8a] px-20 py-3 font-semibold text-2xl rounded-full w-72" @click="validateStep()">BELÉPÉS</button>
    <button v-if="isLoading" class="inline-flex items-center bg-[#00ff8a] px-20 py-3 rounded-full w-80 cursor-not-allowed text-2xl font-semibold" disabled>
      <svg class="mr-3 -ml-1 text-black w-7 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      BELÉPÉS...
    </button>
  </div>
</template>
