<script setup lang="ts">
  import { useStore } from './store/index'

  const store = useStore()

  const urlParameters = Object.fromEntries(new URLSearchParams(location.search.substring(1)))

  store.$patch({
    urlParameters: {
      ...urlParameters,
    },
  })
</script>

<template>
  <router-view />
</template>
