import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from '../views/Index.vue'
import Registration from '../views/Registration.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/regisztracio',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/adatvedelmi-szabalyzat',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/aszf',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
