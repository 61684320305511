<script setup lang="ts">
  import { useStore } from '../store/index'
  // import { useI18n } from 'vue-i18n'
  import { ref } from 'vue'
  import TheFooter from '../components/TheFooter.vue'
  import TheHeader from '../components/TheHeader.vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Navigation } from 'swiper'
  import 'swiper/css/bundle'
  import { useRouter } from 'vue-router'

  const store = useStore()
  const router = useRouter()

  const urlParameters = Object.fromEntries(new URLSearchParams(location.search.substring(1)))
  urlParameters.aff_id = `mindshu_${urlParameters.src}` || ''
  urlParameters.sub_id = urlParameters.gclid || 'direct'
  if (urlParameters.device === undefined) {
    urlParameters.device = 'mobile'
  }
  urlParameters.device = urlParameters.device.toLowerCase()
  if (urlParameters.device !== 'desktop' && urlParameters.device !== 'tablet') {
    urlParameters.device = 'mobile'
  }

  store.$patch({
    urlParameters: {
      ...urlParameters,
    },
  })

  const gender = ref('male')
  const preference = ref('female')

  function selectGender(genderType: string) {
    gender.value = genderType
  }

  function selectPreference(preferenceType: string) {
    preference.value = preferenceType
  }

  function goToRegistration() {
    router.push('/regisztracio')
  }

  const onlineUsers = ref(4720)

  setInterval(() => {
    const random = ref(Math.floor(Math.random() * 3 + 1))
    const plusOrMinus = ref(Math.random() < 0.5 ? -1 : 1)
    random.value = random.value * plusOrMinus.value
    onlineUsers.value = onlineUsers.value + random.value
  }, 3000)
</script>

<template>
  <div class="h-screen w-screen bg-[#1d2a3b] overflow-x-hidden">
    <TheHeader class="bg-[#1d2a3b]" />
    <div class="relative w-full bg-top bg-no-repeat bg-cover h-hero bg-hero">
      <div
        class="absolute bg-white md:h-[28rem] w-11/12 sm:w-96 left-0 right-0 mx-auto md:left-auto md:right-16 top-16 rounded-lg shadow-2xl flex flex-col space-y-4 drop-shadow-lg min-h-fit transpare bg-opacity-90"
        v-motion-roll-right
        :delay="100"
      >
        <h2 class="mt-3 text-2xl font-bold text-center sm:text-3xl">Ingyenes regisztráció</h2>
        <div class="flex flex-col w-full mt-2">
          <h2 class="mb-2 text-xl font-semibold text-center sm:text-2xl">Nemem</h2>
          <div class="flex flex-row justify-center w-full space-x-10">
            <div
              class="flex flex-col items-center justify-center border-4 rounded-full cursor-pointer w-14 h-14 sm:w-20 sm:h-20"
              :class="{ 'border-[#01ff8b]': gender === 'male', 'border-[#e2e2e2]': gender === 'female' }"
              @click="selectGender('male')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#0e9cb2] w-11 h-11 sm:w-14 sm:h-14">
                <path
                  d="M208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48zM152 352V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480V256.9L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L62.85 174.6C80.2 145.7 111.4 128 145.1 128H174.9C208.6 128 239.8 145.7 257.2 174.6L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L232 256.9V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V352L152 352z"
                />
              </svg>
            </div>
            <div
              class="flex flex-col items-center justify-center border-4 rounded-full cursor-pointer h-14 w-14 sm:w-20 sm:h-20"
              :class="{ 'border-[#01ff8b]': gender === 'female', 'border-[#e2e2e2]': gender === 'male' }"
              @click="selectGender('female')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] sm:w-14 sm:h-14 w-11 h-11">
                <path
                  d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full mt-2">
          <h2 class="mb-2 text-2xl font-semibold text-center">Keresek</h2>
          <div class="flex flex-row justify-center w-full space-x-10">
            <div
              class="flex flex-col items-center justify-center border-4 rounded-full cursor-pointer w-14 h-14 sm:w-20 sm:h-20"
              :class="{ 'border-[#01ff8b]': preference === 'male', 'border-[#e2e2e2]': preference === 'female' }"
              @click="selectPreference('male')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#0e9cb2] sm:w-14 sm:h-14 w-11 h-11">
                <path
                  d="M208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48zM152 352V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480V256.9L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L62.85 174.6C80.2 145.7 111.4 128 145.1 128H174.9C208.6 128 239.8 145.7 257.2 174.6L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L232 256.9V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V352L152 352z"
                />
              </svg>
            </div>
            <div
              class="sm:w-20 sm:h-20 w-14 h-14 border-4 rounded-full border-[#01ff8b] flex flex-col justify-center items-center cursor-pointer"
              :class="{ 'border-[#01ff8b]': preference === 'female', 'border-[#e2e2e2]': preference === 'male' }"
              @click="selectPreference('female')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] sm:w-14 sm:h-14 w-11 h-11">
                <path
                  d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-center pb-3">
          <button class="bg-[#00fe8a] min-w-max px-10 py-3 text-xl sm:text-3xl font-extrabold rounded-full mt-5 uppercase" @click="goToRegistration()">Regisztrálok</button>
        </div>
      </div>
      <div class="absolute bottom-0 flex flex-row w-full md:w-1/2 -translate-x-1/2 bg-[#1d2a3b] left-1/2 justify-around rounded-t-3xl p-1 drop-shadow-md">
        <div class="flex flex-col items-center justify-center" v-motion-roll-bottom :delay="100">
          <h2 class="text-[#00fe8a] md:text-xl font-extrabold">68%</h2>
          <div class="flex flex-row items-center justify-center bg-white rounded-full w-14 h-14 md:w-20 md:h-20">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-10 h-10 md:w-14 md:h-14">
              <path
                d="M112 48C112 21.49 133.5 0 160 0C186.5 0 208 21.49 208 48C208 74.51 186.5 96 160 96C133.5 96 112 74.51 112 48zM88 384H70.2C59.28 384 51.57 373.3 55.02 362.9L93.28 248.1L59.43 304.5C50.33 319.6 30.67 324.5 15.52 315.4C.3696 306.3-4.531 286.7 4.573 271.5L58.18 182.3C78.43 148.6 114.9 128 154.2 128H165.8C205.1 128 241.6 148.6 261.8 182.3L315.4 271.5C324.5 286.7 319.6 306.3 304.5 315.4C289.3 324.5 269.7 319.6 260.6 304.5L226.7 248.1L264.1 362.9C268.4 373.3 260.7 384 249.8 384H232V480C232 497.7 217.7 512 200 512C182.3 512 168 497.7 168 480V384H152V480C152 497.7 137.7 512 120 512C102.3 512 88 497.7 88 480L88 384z"
              />
            </svg>
          </div>
          <h2 class="text-[#00fe8a] md:text-lg font-semibold text-center">Hölgy felhasználó</h2>
        </div>
        <div class="flex flex-col items-center justify-center" v-motion-roll-bottom :delay="100">
          <h2 class="text-[#00fe8a] md:text-xl font-extrabold">87%</h2>
          <div class="flex flex-row items-center justify-center bg-white rounded-full w-14 h-14 md:w-20 md:h-20">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fill-[#fc0089] w-10 h-10 md:w-14 md:h-14">
              <path
                d="M176 288C220.1 288 256 252.1 256 208S220.1 128 176 128S96 163.9 96 208S131.9 288 176 288zM544 128H304C295.2 128 288 135.2 288 144V320H64V48C64 39.16 56.84 32 48 32h-32C7.163 32 0 39.16 0 48v416C0 472.8 7.163 480 16 480h32C56.84 480 64 472.8 64 464V416h512v48c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16V224C640 170.1 597 128 544 128z"
              />
            </svg>
          </div>
          <h2 class="text-[#00fe8a] md:text-lg font-semibold text-center">Alkalmi kapcsolatra nyitott</h2>
        </div>
        <div class="flex flex-col items-center justify-center" v-motion-roll-bottom :delay="100">
          <h2 class="text-[#00fe8a] md:text-xl font-extrabold">100%</h2>
          <div class="flex flex-row items-center justify-center bg-white rounded-full w-14 h-14 md:w-20 md:h-20">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="fill-[#fc0089] w-10 h-10 md:w-14 md:h-14">
              <path
                d="M160 0C177.7 0 192 14.33 192 32V67.68C193.6 67.89 195.1 68.12 196.7 68.35C207.3 69.93 238.9 75.02 251.9 78.31C268.1 82.65 279.4 100.1 275 117.2C270.7 134.3 253.3 144.7 236.1 140.4C226.8 137.1 198.5 133.3 187.3 131.7C155.2 126.9 127.7 129.3 108.8 136.5C90.52 143.5 82.93 153.4 80.92 164.5C78.98 175.2 80.45 181.3 82.21 185.1C84.1 189.1 87.79 193.6 95.14 198.5C111.4 209.2 136.2 216.4 168.4 225.1L171.2 225.9C199.6 233.6 234.4 243.1 260.2 260.2C274.3 269.6 287.6 282.3 295.8 299.9C304.1 317.7 305.9 337.7 302.1 358.1C295.1 397 268.1 422.4 236.4 435.6C222.8 441.2 207.8 444.8 192 446.6V480C192 497.7 177.7 512 160 512C142.3 512 128 497.7 128 480V445.1C127.6 445.1 127.1 444.1 126.7 444.9L126.5 444.9C102.2 441.1 62.07 430.6 35 418.6C18.85 411.4 11.58 392.5 18.76 376.3C25.94 360.2 44.85 352.9 60.1 360.1C81.9 369.4 116.3 378.5 136.2 381.6C168.2 386.4 194.5 383.6 212.3 376.4C229.2 369.5 236.9 359.5 239.1 347.5C241 336.8 239.6 330.7 237.8 326.9C235.9 322.9 232.2 318.4 224.9 313.5C208.6 302.8 183.8 295.6 151.6 286.9L148.8 286.1C120.4 278.4 85.58 268.9 59.76 251.8C45.65 242.4 32.43 229.7 24.22 212.1C15.89 194.3 14.08 174.3 17.95 153C25.03 114.1 53.05 89.29 85.96 76.73C98.98 71.76 113.1 68.49 128 66.73V32C128 14.33 142.3 0 160 0V0z"
              />
            </svg>
          </div>
          <h2 class="text-[#00fe8a] md:text-lg font-semibold text-center">Ingyenes regisztráció</h2>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col w-full pt-5 space-y-3 bg-white pb-36">
      <h1 class="text-center text-[#787979] text-3xl md:text-4xl font-bold uppercase mt-10 mb-10" v-motion-slide-left :delay="100">Népszerű tagjaink</h1>
      <div class="grid grid-cols-2 gap-4 mx-auto md:grid-cols-5">
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-left :delay="100">
          <img src="../assets/images/member_1.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-left :delay="100">
          <img src="../assets/images/member_2.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-roll-right :delay="100">
          <img src="../assets/images/member_3.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-right :delay="100">
          <img src="../assets/images/member_4.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-right :delay="100">
          <img src="../assets/images/member_5.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-left :delay="100">
          <img src="../assets/images/member_6.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-left :delay="100">
          <img src="../assets/images/member_7.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-top :delay="100">
          <img src="../assets/images/member_8.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-right :delay="100">
          <img src="../assets/images/member_9.jpg" alt="" />
        </div>
        <div class="w-32 h-32 rounded-full" v-motion-slide-visible-once-right :delay="100">
          <img src="../assets/images/member_10.jpg" alt="" />
        </div>
      </div>
      <div class="absolute bg-[#1d2a3b] -bottom-[3.1rem] -translate-x-1/2 left-1/2 flex flex-row justify-center items-center rounded-3xl px-20 space-x-6 py-2 z-30">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-16 h-16 md:w-20 md:h-20 fill-white">
          <path
            d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z"
          />
        </svg>
        <h2 class="text-3xl text-white md:text-4xl font-semigold" v-motion-roll-visible-once-left :delay="100">Online tagok:</h2>
        <span class="text-4xl md:text-4xl text-[#fc0089] font-extrabold" v-motion-pop-visible-once :delay="100">{{ onlineUsers }}</span>
      </div>
    </div>
    <div class="visible w-full mt-2 bg-white lg:hidden">
      <h1 class="text-center text-[#787979] text-3xl md:text-4xl font-bold uppercase pt-20" v-motion-slide-left :delay="100">Rólunk írták</h1>
      <swiper :navigation="true" :loop="true" :modules="[Navigation]" class="mx-auto mySwiper" :centeredSlides="true" :centerInsufficientSlides="true">
        <swiper-slide>
          <div class="relative flex flex-col w-full mt-24 mb-10" v-motion-slide-visible-once-left :delay="100">
            <div class="bg-[#2a9d8e] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between mx-auto relative">
              <div>
                <span class="text-lg text-white"
                  >"A válásom után rengeteg alkalmi társkeresőt kipróbáltam, hogy betöltsem az "űrt", de sajnos nem jártam sikerrel. Aztán szembejött a TárskeresőChat, ha Te is szeretnél hetente más
                  nővel ágyba bújni akkor ne keress tovább."</span
                >
              </div>
              <div class="flex flex-col items-end w-full text-right">
                <div class="w-48 h-1 text-right bg-white"></div>
                <span class="text-xl font-semibold text-white">Zoltán</span>
                <span class="text-xl text-white uppercase">Budapest</span>
              </div>
              <div class="bg-[#1d2a3b] absolute w-20 h-5 rounded-xl z-40 -top-2 left-[1rem]"></div>
              <div class="z-50 bg-black absolute rounded-full w-28 h-28 -bottom-6 left-[-1rem]">
                <img src="../assets/images/profile_1.jpg" alt="" class="rounded-full" />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="relative flex flex-col w-full mt-24 mb-10" v-motion-slide-visible-once-left :delay="100">
            <div class="bg-[#e77052] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between mx-auto relative">
              <div>
                <span class="text-lg text-white"
                  >"Otthon nem kapom meg amire vágyok ezért kerestem alkalmi partnert egy ideje. Azt kell, hogy mondjam, ennél gyorsabb és könnyebben használható felülettel még nem találkoztam.
                  Köszönöm még egyszer!"</span
                >
              </div>
              <div class="flex flex-col items-end w-full text-right">
                <div class="w-48 h-1 text-right bg-white"></div>
                <span class="text-xl font-semibold text-white">Nóra</span>
                <span class="text-xl text-white uppercase">Debrecen</span>
              </div>
              <div class="bg-[#1d2a3b] absolute w-20 h-5 rounded-xl z-40 -top-2 left-[1rem]"></div>
              <div class="z-50 bg-black absolute rounded-full w-28 h-28 -bottom-6 left-[-1rem]">
                <img src="../assets/images/profile_2.jpg" alt="" class="rounded-full" />
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="relative flex flex-col w-full mt-24 mb-10" v-motion-slide-visible-once-left :delay="100">
            <div class="bg-[#d49e18] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between mx-auto relative">
              <div>
                <span class="text-lg text-white"
                  >"Ez a legjobb társkereső, amit valaha használtam! A felhasználók ugyanazt keresik, mint én, nincs mellébeszélés. Az elmúlt 2 hétben 3 nővel jöttem össze, nem vagyok, az a hencegős
                  fajta, de istenien éreztük magunkat. "</span
                >
              </div>
              <div class="flex flex-col items-end w-full text-right">
                <div class="w-48 h-1 text-right bg-white"></div>
                <span class="text-xl font-semibold text-white">Péter</span>
                <span class="text-xl text-white uppercase">Budapest</span>
              </div>
              <div class="bg-[#1d2a3b] absolute w-20 h-5 rounded-xl z-40 -top-2 left-[1rem]"></div>
              <div class="z-50 bg-black absolute rounded-full w-28 h-28 -bottom-6 left-[-1rem]">
                <img src="../assets/images/profile_3.jpg" alt="" class="rounded-full" />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="relative flex-col hidden w-full mt-2 bg-white pb-44 lg:flex">
      <h1 class="text-center text-[#787979] text-3xl md:text-4xl font-bold uppercase mt-20 mb-10" v-motion-slide-left :delay="100">Rólunk írták</h1>
      <div class="relative flex flex-row justify-center mx-auto space-x-20">
        <div class="relative flex flex-col" v-motion-slide-visible-once-left :delay="100">
          <div class="bg-[#2a9d8e] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between">
            <div class="w-full">
              <span class="w-full text-lg text-white">
                "A válásom után rengeteg alkalmi társkeresőt kipróbáltam, hogy betöltsem az "űrt", de sajnos nem jártam sikerrel. Aztán szembejött a TárskeresőChat, ha Te is szeretnél hetente más
                nővel ágyba bújni akkor ne keress tovább."
              </span>
            </div>
            <div class="flex flex-col items-end w-full text-right">
              <div class="w-48 h-1 text-right bg-white"></div>
              <span class="text-xl font-semibold text-white">Zoltán</span>
              <span class="text-xl text-white uppercase">Budapest</span>
            </div>
          </div>
          <div class="bg-[#1d2a3b] w-64 h-[22rem] rounded-2xl absolute left-1 -bottom-1"></div>
          <div class="bg-[#1d2a3b] w-20 h-5 rounded-xl absolute z-40 -top-2 left-4"></div>
          <div class="absolute z-50 w-32 h-32 bg-black rounded-full -bottom-14 -left-14">
            <img src="../assets/images/profile_1.jpg" alt="" class="rounded-full" />
          </div>
        </div>
        <div class="relative flex flex-col" v-motion-slide-visible-once-left :delay="100">
          <div class="bg-[#e77052] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between">
            <div>
              <span class="text-lg text-white"
                >"Otthon nem kapom meg amire vágyok ezért kerestem alkalmi partnert egy ideje. Azt kell, hogy mondjam, ennél gyorsabb és könnyebben használható felülettel még nem találkoztam. Köszönöm
                még egyszer!"</span
              >
            </div>
            <div class="flex flex-col items-end w-full text-right">
              <div class="w-48 h-1 text-right bg-white"></div>
              <span class="text-xl font-semibold text-white">Nóra</span>
              <span class="text-xl text-white uppercase">Debrecen</span>
            </div>
          </div>
          <div class="bg-[#1d2a3b] w-64 h-[22rem] rounded-2xl absolute left-1 -bottom-1"></div>
          <div class="bg-[#1d2a3b] w-20 h-5 rounded-xl absolute z-40 -top-2 left-4"></div>
          <div class="absolute z-50 w-32 h-32 bg-black rounded-full -bottom-14 -left-14">
            <img src="../assets/images/profile_2.jpg" alt="" class="rounded-full" />
          </div>
        </div>
        <div class="relative flex flex-col" v-motion-slide-visible-once-left :delay="100">
          <div class="bg-[#d49e18] w-64 h-[22rem] rounded-2xl z-30 flex flex-col p-3 place-content-between">
            <div>
              <span class="text-lg text-white"
                >"Ez a legjobb társkereső, amit valaha használtam! A felhasználók ugyanazt keresik, mint én, nincs mellébeszélés. Az elmúlt 2 hétben 3 nővel jöttem össze, nem vagyok, az a hencegős
                fajta, de istenien éreztük magunkat. "</span
              >
            </div>
            <div class="flex flex-col items-end w-full text-right">
              <div class="w-48 h-1 text-right bg-white"></div>
              <span class="text-xl font-semibold text-white">Péter</span>
              <span class="text-xl text-white uppercase">Budapest</span>
            </div>
          </div>
          <div class="bg-[#1d2a3b] w-64 h-[22rem] rounded-2xl absolute left-1 -bottom-1"></div>
          <div class="bg-[#1d2a3b] w-20 h-5 rounded-xl absolute z-40 -top-2 left-4"></div>
          <div class="absolute z-50 w-32 h-32 bg-black rounded-full -bottom-14 -left-14">
            <img src="../assets/images/profile_3.jpg" alt="" class="rounded-full" />
          </div>
        </div>
      </div>
      <div
        class="absolute bg-[#1d2a3b] -translate-x-1/2 left-1/2 flex flex-col md:flex-row justify-center items-center rounded-3xl md:px-20 px-5 md:space-x-6 py-5 z-30 -bottom-[3.7rem] w-11/12 md:w-3/5"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-10 h-10 md:w-20 md:h-20 fill-white" v-motion-slide-visible-once-left>
          <path
            d="M272 0C289.7 0 304 14.33 304 32C304 49.67 289.7 64 272 64H256V98.45C293.5 104.2 327.7 120 355.7 143L377.4 121.4C389.9 108.9 410.1 108.9 422.6 121.4C435.1 133.9 435.1 154.1 422.6 166.6L398.5 190.8C419.7 223.3 432 262.2 432 304C432 418.9 338.9 512 224 512C109.1 512 16 418.9 16 304C16 200 92.32 113.8 192 98.45V64H176C158.3 64 144 49.67 144 32C144 14.33 158.3 0 176 0L272 0zM248 192C248 178.7 237.3 168 224 168C210.7 168 200 178.7 200 192V320C200 333.3 210.7 344 224 344C237.3 344 248 333.3 248 320V192z"
          />
        </svg>
        <div class="w-full">
          <h2 class="text-xl text-center text-white md:text-3xl">Tagjaink <span class="font-bold"> 82%</span>-a az <span class="font-bold">első héten</span> alkalmi párt talál oldalunkon</h2>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full pb-10 mt-2 bg-white">
      <h1 class="text-center text-[#787979] text-3xl md:text-4xl font-bold uppercase mt-20 mb-10" v-motion-slide-visible-once-right :delay="100">Miért válassz minket?</h1>
      <div class="flex flex-col items-center justify-center px-2 mx-auto space-y-5 max-w-7xl">
        <div class="flex flex-col md:flex-row space-y-7">
          <div class="md:w-1/2" v-motion-slide-visible-once-left>
            <img src="../assets/images/feature_01.png" alt="" class="w-[30rem]" />
          </div>
          <div class="flex flex-col justify-center md:w-1/2" v-motion-slide-visible-once-right :delay="100">
            <div class="flex flex-col self-center w-full max-w-sm space-y-3 text-center">
              <h2 class="text-3xl font-semibold text-center md:text-left">Gondtalan társkeresés</h2>
              <span class="text-2xl text-center md:text-left">Chatelj és ismerkedj a telefonodról, vagy számítógépedről. Bárhol és bármikor!</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="flex flex-col justify-center order-last md:order-none md:w-1/2" v-motion-slide-visible-once-left>
            <div class="flex flex-col self-center max-w-sm">
              <h2 class="mt-10 text-3xl font-semibold text-center md:mt-0 md:text-left">Nincs mellébeszélész!</h2>
              <span class="text-2xl text-center md:text-left"
                >Felhasználóink kalandvágyó emberek ezért nem kell kertelned! ha alkalmi partnert keresel, jelezd. Szinte biztos, hogy nyitott kapukat döngetsz!</span
              >
            </div>
          </div>
          <div class="md:w-1/2">
            <img src="../assets/images/feature_02.png" alt="" class="w-[30rem]" v-motion-slide-visible-once-right :delay="100" />
          </div>
        </div>
        <div class="flex flex-col md:flex-row space-y-7">
          <div class="mt-10 md:w-1/2 md:mt-0">
            <img src="../assets/images/feature_03.png" alt="" class="w-[30rem]" v-motion-slide-visible-once-left :delay="100" />
          </div>
          <div class="flex flex-col justify-center md:w-1/2" v-motion-slide-visible-once-right>
            <div class="flex flex-col self-center max-w-sm">
              <h2 class="text-3xl font-semibold text-center md:text-left">Kockázatmentes!</h2>
              <span class="text-2xl text-center md:text-left"
                >A <span class="text-blue-600 underline cursor-pointer" @click="goToRegistration()">regisztráció</span> 100%-ig ingyenes! Regisztrálj ingyen és nézz körül oldalunkon.</span
              >
              <span class="text-2xl text-center md:text-left">Nincs szükség bakkártya megadására a regisztrációhoz!</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center justify-center mt-10 space-y-10">
        <div class="w-7 h-7 bg-[#fc0089] rounded-full" v-motion-slide-visible-once-bottom :delay="100"></div>
        <div class="w-7 h-7 bg-[#fc0089] rounded-full" v-motion-slide-visible-once-bottom :delay="100"></div>
        <div class="w-7 h-7 bg-[#fc0089] rounded-full" v-motion-slide-visible-once-bottom :delay="100"></div>
      </div>
      <div class="flex flex-col items-center justify-center mt-6">
        <h2 class="text-4xl font-semibold text-[#5f5f5f] text-center" v-motion-slide-visible-once-bottom :delay="100">Mire vársz még? Csatlakozz most!</h2>
        <img src="../assets/images/arrow_new.png" alt="" class="mt-5 w-36" v-motion-slide-visible-once-bottom :delay="100" />
        <button class="bg-[#00fe8a] text-black font-extrabold text-4xl py-6 px-10 rounded-full mt-5 uppercase" v-motion-slide-visible-once-left @click="goToRegistration()">Regisztrálok</button>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<style>
  .h-hero {
    height: calc(100vh - 6rem) !important;
  }
</style>
